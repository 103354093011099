
import { Component, Vue, Watch } from 'vue-property-decorator';
/* COMPONENTS */
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import Header from '@/modules/landingPage/components/layout/Header.vue';
import Footer from '@/modules/landingPage/components/layout/Footer.vue';
/* INTERFACES */
/* STORE */
import { auth } from '@/store/namespaces';
import AuthMethods from '@/store/auth/methods/auth.methods';

@Component({
  components: {
      SvgIcon,
      Header,
      Footer
  },
})
export default class Us extends Vue {
      purposeList=[
        {
            icon: 'mission',
            title: 'Misión',
            text: 'Transformar la industria de servicios financieros para migrantes y nómadas del mundo.'
        },
        {
            icon: 'vision',
            title: 'Visión',
            text: 'Impulsar la inclusión financiera a traves de una plataforma digital, ágil, justa y sin fronteras.'
        },
        {
            icon: 'purpose',
            title: 'Propósito',
            text: 'Ofrecer servicios financieros invovadores, adaptados a las necesidades de los migrantes y nomadas del mundo..'
        }
    ]
    ourTeamList=[
        {
            icon: 'lightbulb',
            title: 'Innovadores',
            text: 'Mejoramos siempre y buscamos constantemente cambios que agreguen valor a la sociedad.'
        },
        {
            icon: 'crosshair',
            title: 'Comprometidos',
            text: 'Cumplimos contigo y nos enfocamos en un servicio justo, humano e incluyente.'
        },
        {
            icon: 'heart',
            title: 'Apasionados',
            text: 'Amamos lo que hacemos y nos esmeramos por brindar servicios de calidad en todo momento.'
        }
    ]


    goToSignUp(){
        if (this.isAuthenticated)
            this.$router.push({name:'Dashboard'}).catch();
        else
            this.$router.push({name:'CreateAccount'}).catch()
    }

    /* AUTH */
    @auth.Getter(AuthMethods.getters.GET_AUTHENTICATION)
    isAuthenticated!: boolean;
}
